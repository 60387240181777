import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import { setTitle } from 'Store/Reducers/appInfoSlice';

import ProgressButtonGroupComponent from 'Components/ProgressButtonGroupComponent/ProgressButtonGroupComponent';
import TextFieldComponent from 'Components/TextFieldComponent/TextFieldComponent';
import { useAuthMailOrderInitMutation } from 'services/mailOrder';
import useAuth from 'Hooks/useAuth';
import { useGetUserDetailsQuery, useGetAdvocateInfoQuery } from 'services/user';
import { MailOrderProps } from '../';

const confirmNeedType = 'ColdPckProvision';

interface TempSensitiveLayoutValues {
  initials: string;
}

const TempSensitiveLayout: FunctionComponent<MailOrderProps> = ({
  nextOnClickCallback,
  previousOnClickCallback,
  onSaveData,
  contextData,
  savedData,
}) => {
  const dispatch = useDispatch();
  const { mailOrderAuthKeystring: mailOrderId } = contextData;
  const {
    data: { personIdEncryptedMaybe: personId },
  } = useAuth();
  const { data: userDetails } = useGetUserDetailsQuery(personId);
  const { firstName, lastName } = userDetails || {};
  const userInitials = `${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`;

  const [putAuthMailOrderInit] = useAuthMailOrderInitMutation();

  useEffect(() => {
    dispatch(setTitle('Mail Order Authorization'));
  }, [dispatch]);

  const tempSensitiveSchema = Yup.object().shape({
    initials: Yup.string()
      .uppercase()
      .oneOf([userInitials], "Characters don't match your initials")
      .required('Initials Required'),
  });

  const useStyles = makeStyles((theme) => ({
    screenBox: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        initials: (savedData.tempSensitive as string) || '',
      }}
      validationSchema={tempSensitiveSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveData({
          tempSensitive: values.initials,
        });

        const mailOrderInitInfo = {
          mailOrderId,
          authorizationInitials: values.initials,
          confirmationNeedType: confirmNeedType,
        };

        putAuthMailOrderInit(mailOrderInitInfo);
        setSubmitting(false);
        nextOnClickCallback();
      }}
    >
      {({ isSubmitting, submitForm }: FormikProps<TempSensitiveLayoutValues>) => (
        <Form>
          <Box
            className={classes.screenBox}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              align={'center'}
              variant={'body1'}
              color={'textPrimary'}
              style={{ fontWeight: 'bold' }}
            >
              Temperature Sensitive Drug Policy
            </Typography>
            <Paper elevation={2}>
              <Typography
                align={'left'}
                variant={'body1'}
                color={'textPrimary'}
                style={{ overflow: 'scroll', height: '250px', margin: '0.5em' }}
              >
                You will be emailed a tracking number for your temperature sensitive package. All
                temperature sensitive medications are shipped by AIR Express delivery, which is sent
                mandatory signature required. Once you have received your tracking number and your
                order has shipped, it should arrive in approximately 2-5 days.
                <br />
                <br />
                You may change the shipping address if you would like it delivered to your work, a
                nearby neighbor, friend, or family member who would be able to sign for your package
                upon receipt. If you are not home when your package is delivered, you can contact
                the shipping carrier to redeliver your package by following the link sent with your
                tracking number or visiting their website.
                <br />
                <br />
                Please open your package right away and inspect its contents. Orders are packed in a
                thermal insulated wrapper, with an icepack, inside a Styrofoam box. During the
                summer months, extra ice packs are added. The ice pack(s) may be thawed upon
                arrival. It is the insulation that will help to keep it cooler, longer. If your
                medication does not feel cool to the touch, or you find any other issues with your
                shipment, immediately take pictures of the medication, save the temperature logger
                (look thoroughly through the packaging for the logger so it is not thrown out) and
                all packaging and inform us right away.
                <br />
              </Typography>
            </Paper>
            <Box display={'flex'} marginTop={2}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>
                I, {firstName} {lastName}, verify that I have read the Temperature Sensitive Drug
                Policy described above.
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={1}
              marginBottom={1}
            >
              <Box flexBasis={'70%'}>
                <TextFieldComponent
                  label="Initial Here"
                  name={'initials'}
                  fullWidth={false}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e: { target: { value: string } }) => {
                    e.target.value = e.target.value.slice(0, 2);
                  }}
                />
              </Box>
            </Box>
            <Box marginBottom={1}>
              <ProgressButtonGroupComponent
                isLoading={isSubmitting}
                nextOnClickCallback={submitForm}
                previousOnClickCallback={previousOnClickCallback}
                nextButtonProps={{ text: 'Next' }}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TempSensitiveLayout;
